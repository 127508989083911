import { default as indexXfh5dfw6piMeta } from "/var/www/stage/qst/pages/index.vue?macro=true";
import { default as indeximIWn15cpDMeta } from "/var/www/stage/qst/pages/information/index.vue?macro=true";
import { default as _91slug_93CEXwYPa31EMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/index/[slug].vue?macro=true";
import { default as indexBEbhzmXuURMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/index/index.vue?macro=true";
import { default as indexIBBWEeMRV1Meta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/index.vue?macro=true";
import { default as _91slug_934BqPGXozSJMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/news/[slug].vue?macro=true";
import { default as indexY5oaWlnYVYMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/news/index.vue?macro=true";
import { default as newsLOOEcu2q5eMeta } from "/var/www/stage/qst/pages/information/subpage/index/[category]/news.vue?macro=true";
import { default as index8dH7WZNlSbMeta } from "/var/www/stage/qst/pages/information/subpage/index.vue?macro=true";
import { default as personal_45information_45policyzxkLpHj8kPMeta } from "/var/www/stage/qst/pages/personal-information-policy.vue?macro=true";
import { default as indexXjwMwHlWDbMeta } from "/var/www/stage/qst/pages/prevention/index.vue?macro=true";
import { default as _91slug_93kzqIpL8E6FMeta } from "/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/[slug].vue?macro=true";
import { default as indexT1xfxwPO48Meta } from "/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/index.vue?macro=true";
import { default as indexmgmi0PJLOdMeta } from "/var/www/stage/qst/pages/prevention/subpage/index/[category]/index.vue?macro=true";
import { default as indexfCglewJOXZMeta } from "/var/www/stage/qst/pages/prevention/subpage/index.vue?macro=true";
import { default as privacy_45policyVz5Dehg7uBMeta } from "/var/www/stage/qst/pages/privacy-policy.vue?macro=true";
import { default as acm_452021AWZ2xIJ3VaMeta } from "/var/www/stage/qst/pages/quit/forms/acm-2021.vue?macro=true";
import { default as healthcareStx2MWEU4rMeta } from "/var/www/stage/qst/pages/quit/forms/healthcare.vue?macro=true";
import { default as newsletterQ4KRIdRM5QMeta } from "/var/www/stage/qst/pages/quit/forms/newsletter.vue?macro=true";
import { default as order9UFtAz4ViiMeta } from "/var/www/stage/qst/pages/quit/forms/order.vue?macro=true";
import { default as pharmacy3ORcY1ewHOMeta } from "/var/www/stage/qst/pages/quit/forms/pharmacy.vue?macro=true";
import { default as vapeM49NEfgcWeMeta } from "/var/www/stage/qst/pages/quit/forms/vape.vue?macro=true";
import { default as indexJD4U2CCBqWMeta } from "/var/www/stage/qst/pages/quit/index.vue?macro=true";
import { default as index0JAlqfXayBMeta } from "/var/www/stage/qst/pages/quit/subpage/index/help/index.vue?macro=true";
import { default as personKZttjNR44mMeta } from "/var/www/stage/qst/pages/quit/subpage/index/help/person.vue?macro=true";
import { default as phone9bNuAg53jwMeta } from "/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue?macro=true";
import { default as smsGoPySt88ToMeta } from "/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue?macro=true";
import { default as confidence_resultsPYROMPm5hxMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence_results.js?macro=true";
import { default as confidencenXx2FWPvuoMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue?macro=true";
import { default as _91slug_93iRyj15QfpxMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue?macro=true";
import { default as indexC4helW67juMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue?macro=true";
import { default as _91slug_93U49Umz11x6Meta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue?macro=true";
import { default as indexi1jYrRnDWkMeta } from "/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue?macro=true";
import { default as proXE9GnoCCp6Meta } from "/var/www/stage/qst/pages/quit/subpage/index/pro.vue?macro=true";
import { default as indexSZQ0SvXBXlMeta } from "/var/www/stage/qst/pages/quit/subpage/index.vue?macro=true";
import { default as responsabilityxItVbDXgL1Meta } from "/var/www/stage/qst/pages/responsability.vue?macro=true";
import { default as searchJn2SanC09aMeta } from "/var/www/stage/qst/pages/search.vue?macro=true";
import { default as sharejiG0hIubY2Meta } from "/var/www/stage/qst/pages/share.vue?macro=true";
import { default as sitemap2saQ6ntYDcMeta } from "/var/www/stage/qst/pages/sitemap.vue?macro=true";
import { default as component_45stubq5xUrmReWCMeta } from "/var/www/stage/qst/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubq5xUrmReWC } from "/var/www/stage/qst/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___fr",
    path: "/",
    component: () => import("/var/www/stage/qst/pages/index.vue")
  },
  {
    name: "information___fr",
    path: "/je-minforme",
    component: () => import("/var/www/stage/qst/pages/information/index.vue")
  },
  {
    name: "information-subpage___en",
    path: "/information/subpage",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index.vue"),
    children: [
  {
    name: indexIBBWEeMRV1Meta?.name,
    path: ":category()",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index.vue"),
    children: [
  {
    name: "information-subpage-index-category-index-slug___fr",
    path: "/je-minforme/:post()",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index/[slug].vue")
  },
  {
    name: "information-subpage-index-category-index___fr",
    path: "/je-minforme/:category()",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index/index.vue")
  }
]
  },
  {
    name: newsLOOEcu2q5eMeta?.name,
    path: ":category()/news",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news.vue"),
    children: [
  {
    name: "information-subpage-index-category-news-slug___fr",
    path: "/je-minforme/actualites/:tag()/:post()",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news/[slug].vue")
  },
  {
    name: "information-subpage-index-category-news___fr",
    path: "/je-minforme/actualites/:tag?",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news/index.vue")
  }
]
  }
]
  },
  {
    name: "information-subpage___fr",
    path: "/information/subpage",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index.vue"),
    children: [
  {
    name: indexIBBWEeMRV1Meta?.name,
    path: "/je-minforme/:category()",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index.vue"),
    children: [
  {
    name: "information-subpage-index-category-index-slug___fr",
    path: ":post()",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index/[slug].vue")
  },
  {
    name: "information-subpage-index-category-index___fr",
    path: "/je-minforme/:category()",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/index/index.vue")
  }
]
  },
  {
    name: newsLOOEcu2q5eMeta?.name,
    path: "/je-minforme/actualites/:tag?",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news.vue"),
    children: [
  {
    name: "information-subpage-index-category-news-slug___fr",
    path: "/je-minforme/actualites/:tag()/:post()",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news/[slug].vue")
  },
  {
    name: "information-subpage-index-category-news___fr",
    path: "/je-minforme/actualites/:tag?",
    component: () => import("/var/www/stage/qst/pages/information/subpage/index/[category]/news/index.vue")
  }
]
  }
]
  },
  {
    name: "personal-information-policy___fr",
    path: "/politique-des-renseignements-personnels",
    component: () => import("/var/www/stage/qst/pages/personal-information-policy.vue")
  },
  {
    name: "personal-information-policy___en",
    path: "/personal-information-policy",
    component: () => import("/var/www/stage/qst/pages/personal-information-policy.vue")
  },
  {
    name: "prevention___fr",
    path: "/je-previens",
    component: () => import("/var/www/stage/qst/pages/prevention/index.vue")
  },
  {
    name: "prevention-subpage___en",
    path: "/prevention/subpage",
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index.vue"),
    children: [
  {
    name: indexmgmi0PJLOdMeta?.name,
    path: ":category()",
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index.vue"),
    children: [
  {
    name: "prevention-subpage-index-category-index-slug___fr",
    path: "/je-previens/:post()",
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/[slug].vue")
  },
  {
    name: "prevention-subpage-index-category-index___fr",
    path: "/je-previens/:category()",
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/index.vue")
  }
]
  }
]
  },
  {
    name: "prevention-subpage___fr",
    path: "/prevention/subpage",
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index.vue"),
    children: [
  {
    name: indexmgmi0PJLOdMeta?.name,
    path: "/je-previens/:category()",
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index.vue"),
    children: [
  {
    name: "prevention-subpage-index-category-index-slug___fr",
    path: ":post()",
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/[slug].vue")
  },
  {
    name: "prevention-subpage-index-category-index___fr",
    path: "/je-previens/:category()",
    component: () => import("/var/www/stage/qst/pages/prevention/subpage/index/[category]/index/index.vue")
  }
]
  }
]
  },
  {
    name: "privacy-policy___fr",
    path: "/politique-de-confidentialite",
    component: () => import("/var/www/stage/qst/pages/privacy-policy.vue")
  },
  {
    name: "quit-forms-acm-2021___fr",
    path: "/form/acm-automne-2021",
    component: () => import("/var/www/stage/qst/pages/quit/forms/acm-2021.vue")
  },
  {
    name: "quit-forms-acm-2021___en",
    path: "/form/acm-automne-2021-en",
    component: () => import("/var/www/stage/qst/pages/quit/forms/acm-2021.vue")
  },
  {
    name: "quit-forms-healthcare___fr",
    path: "/jarrete/acces-professionnels/etablissements-de-sante",
    component: () => import("/var/www/stage/qst/pages/quit/forms/healthcare.vue")
  },
  {
    name: "quit-forms-healthcare___en",
    path: "/iquitnow/access/health-facilities",
    component: () => import("/var/www/stage/qst/pages/quit/forms/healthcare.vue")
  },
  {
    name: "quit-forms-newsletter___fr",
    path: "/cqts/infolettre",
    component: () => import("/var/www/stage/qst/pages/quit/forms/newsletter.vue")
  },
  {
    name: "quit-forms-newsletter___en",
    path: "/cqts/infolettre-en",
    component: () => import("/var/www/stage/qst/pages/quit/forms/newsletter.vue")
  },
  {
    name: "quit-forms-order___fr",
    path: "/cqts/commande-jarrete",
    component: () => import("/var/www/stage/qst/pages/quit/forms/order.vue")
  },
  {
    name: "quit-forms-order___en",
    path: "/cqts/commande-jarrete-en",
    component: () => import("/var/www/stage/qst/pages/quit/forms/order.vue")
  },
  {
    name: "quit-forms-pharmacy___fr",
    path: "/jarrete/acces-professionnels/pharmacies",
    component: () => import("/var/www/stage/qst/pages/quit/forms/pharmacy.vue")
  },
  {
    name: "quit-forms-pharmacy___en",
    path: "/iquitnow/access/pharmacies",
    component: () => import("/var/www/stage/qst/pages/quit/forms/pharmacy.vue")
  },
  {
    name: "quit-forms-vape___fr",
    path: "/form/envers-vape-anxiete",
    component: () => import("/var/www/stage/qst/pages/quit/forms/vape.vue")
  },
  {
    name: "quit-forms-vape___en",
    path: "/form/envers-vape-anxiete-en",
    component: () => import("/var/www/stage/qst/pages/quit/forms/vape.vue")
  },
  {
    name: "quit___fr",
    path: "/jarrete",
    component: () => import("/var/www/stage/qst/pages/quit/index.vue")
  },
  {
    name: "quit___en",
    path: "/iquitnow",
    component: () => import("/var/www/stage/qst/pages/quit/index.vue")
  },
  {
    name: "quit-subpage___en",
    path: "/quit/subpage",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index.vue"),
    children: [
  {
    name: "quit-subpage-index-help___fr",
    path: "/jarrete/jai-besoin-daide",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/index.vue")
  },
  {
    name: "quit-subpage-index-help___en",
    path: "/iquitnow/i-need-help",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/index.vue")
  },
  {
    name: "quit-subpage-index-help-person___fr",
    path: "/jarrete/aide-personne",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/person.vue")
  },
  {
    name: "quit-subpage-index-help-person___en",
    path: "/iquitnow/person-help",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/person.vue")
  },
  {
    name: "quit-subpage-index-help-phone___fr",
    path: "/jarrete/aide-telephone",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue")
  },
  {
    name: "quit-subpage-index-help-phone___en",
    path: "/iquitnow/phone-help",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue")
  },
  {
    name: "quit-subpage-index-help-sms___fr",
    path: "/jarrete/aide-texto",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue")
  },
  {
    name: "quit-subpage-index-help-sms___en",
    path: "/iquitnow/text-help",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue")
  },
  {
    name: "quit-subpage-index-hesitate-confidence_results___en",
    path: "hesitate/confidence_results",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence_results.js")
  },
  {
    name: "quit-subpage-index-hesitate-confidence___fr",
    path: "/jarrete/je-me-prepare-a-arreter/votre-confiance",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue")
  },
  {
    name: "quit-subpage-index-hesitate-confidence___en",
    path: "/iquitnow/getting-ready-to-quit/your-confidence",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue")
  },
  {
    name: "quit-subpage-index-hesitate-index-slug___fr",
    path: "/jarrete/je-me-prepare-a-arreter/:post()",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-index-slug___en",
    path: "/iquitnow/getting-ready-to-quit/:post()",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-index___fr",
    path: "/jarrete/je-me-prepare-a-arreter",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-index___en",
    path: "/iquitnow/getting-ready-to-quit",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles-slug___fr",
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/:slug()",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles-slug___en",
    path: "/iquitnow/getting-ready-to-quit/mental-roadblocks/:slug()",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles___fr",
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles___en",
    path: "/iquitnow/getting-ready-to-quit/mental-roadblocks",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue")
  },
  {
    name: "quit-subpage-index-pro___fr",
    path: "/jarrete/acces-professionnels",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/pro.vue")
  },
  {
    name: "quit-subpage-index-pro___en",
    path: "/iquitnow/access-healthcare",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/pro.vue")
  }
]
  },
  {
    name: "quit-subpage___fr",
    path: "/quit/subpage",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index.vue"),
    children: [
  {
    name: "quit-subpage-index-help___fr",
    path: "/jarrete/jai-besoin-daide",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/index.vue")
  },
  {
    name: "quit-subpage-index-help___en",
    path: "/iquitnow/i-need-help",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/index.vue")
  },
  {
    name: "quit-subpage-index-help-person___fr",
    path: "/jarrete/aide-personne",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/person.vue")
  },
  {
    name: "quit-subpage-index-help-person___en",
    path: "/iquitnow/person-help",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/person.vue")
  },
  {
    name: "quit-subpage-index-help-phone___fr",
    path: "/jarrete/aide-telephone",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue")
  },
  {
    name: "quit-subpage-index-help-phone___en",
    path: "/iquitnow/phone-help",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/phone.vue")
  },
  {
    name: "quit-subpage-index-help-sms___fr",
    path: "/jarrete/aide-texto",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue")
  },
  {
    name: "quit-subpage-index-help-sms___en",
    path: "/iquitnow/text-help",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/help/sms.vue")
  },
  {
    name: "quit-subpage-index-hesitate-confidence_results___fr",
    path: "hesitate/confidence_results",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence_results.js")
  },
  {
    name: "quit-subpage-index-hesitate-confidence___fr",
    path: "/jarrete/je-me-prepare-a-arreter/votre-confiance",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue")
  },
  {
    name: "quit-subpage-index-hesitate-confidence___en",
    path: "/iquitnow/getting-ready-to-quit/your-confidence",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/confidence.vue")
  },
  {
    name: "quit-subpage-index-hesitate-index-slug___fr",
    path: "/jarrete/je-me-prepare-a-arreter/:post()",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-index-slug___en",
    path: "/iquitnow/getting-ready-to-quit/:post()",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-index___fr",
    path: "/jarrete/je-me-prepare-a-arreter",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-index___en",
    path: "/iquitnow/getting-ready-to-quit",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/index/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles-slug___fr",
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles/:slug()",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles-slug___en",
    path: "/iquitnow/getting-ready-to-quit/mental-roadblocks/:slug()",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/[slug].vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles___fr",
    path: "/jarrete/je-me-prepare-a-arreter/pensees-obstacles",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue")
  },
  {
    name: "quit-subpage-index-hesitate-obstacles___en",
    path: "/iquitnow/getting-ready-to-quit/mental-roadblocks",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/hesitate/obstacles/index.vue")
  },
  {
    name: "quit-subpage-index-pro___fr",
    path: "/jarrete/acces-professionnels",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/pro.vue")
  },
  {
    name: "quit-subpage-index-pro___en",
    path: "/iquitnow/access-healthcare",
    component: () => import("/var/www/stage/qst/pages/quit/subpage/index/pro.vue")
  }
]
  },
  {
    name: "responsability___fr",
    path: "/conditions-utilisation",
    meta: responsabilityxItVbDXgL1Meta || {},
    component: () => import("/var/www/stage/qst/pages/responsability.vue")
  },
  {
    name: "search___fr",
    path: "/recherche",
    component: () => import("/var/www/stage/qst/pages/search.vue")
  },
  {
    name: "share___fr",
    path: "/progression",
    component: () => import("/var/www/stage/qst/pages/share.vue")
  },
  {
    name: "share___en",
    path: "/progress",
    component: () => import("/var/www/stage/qst/pages/share.vue")
  },
  {
    name: "sitemap___fr",
    path: "/plan-du-site",
    component: () => import("/var/www/stage/qst/pages/sitemap.vue")
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/semaine-pour-un-quebec-sans-tabac-2024",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/avis-non-responsabilite",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/form/formulaire-2",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/form/f",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/acces-professionnels/pharmacie$",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/form/patient-reference-form-from-hosp",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/access/healthcare",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/form/patient-reference-form-from-phar",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/access/pharmacy",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/access$",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/my-plan/exercices",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/member/password/reset",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/access-pharmacy",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/i-need-help",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/your-confidence",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/mental-roadblock/afraid-withdrawal",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/services-de-soutien-a-labandon-du-tabagisme-et-du-vapotage-centres-dabandon-du-tabagisme-du-quebec",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/mental-roadblocks/afraid-withdrawal",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/mental-roadblock/life-boring",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/mental-roadblocks/life-boring",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/mental-roadblock/stop-without-fattening",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/mental-roadblocks/stop-without-fattening",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/mental-roadblock/health-benefits",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/iquitnow/still-hesitating/mental-roadblocks/health-benefits",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/poursuites-judiciaires/recours-collectifs-quebecois-un-nouveau-chapitre-en",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/promotion-des-produits-de-vapotage-le-gouvernement",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/levons-notre-verre-la-2e-saison-des-terrasses-sans-fumee",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/lancement-de-la-strategie-pour-un-quebec-sans-tabac-2020",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/cinq-raisons-meconnues-de-hausser-la-taxe-sur-les",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/lois-et-reglements/adoption-du-projet-de-loi-s-5-la-cigarette-electronique",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/arret-tabagique/lecole-secondaire-du-rocher-adopte-la-toute-premiere$",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/arret-tabagique/le-secret-du-controle-de-soi-comportement-sante-et$",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/dangers-sante-cigarette",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/dependance/bienfaits-arretert",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/formes-tabac",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/industrie-tabac/stratégies-marketing-cigarettiers",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/produits-de-vapotage/lois-et-indusrie",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/produits-de-vapotage-et-cigarette-electronique/cest-quoi",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-previens/ecoles-organismes-jeunesse",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-previens/maison",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/deni",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/?profil=adulte",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jeminforme/industrie-tabac;",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarete/aide-personne",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/jai-besoin-daide",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/services-de-soutien-a-labandon-du-tabagisme-et-du-vapotage-centres-dabandon-du-tabagisme-du-quebec",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/vos-envies",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/votre-confiance",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/arreter-de-fumer",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/arreter-de-fumer",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/benefices-sante",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/benefices-sante",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/craindre-sevrage",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/craindre-sevrage",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/dangers-cigarette",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/dangers-cigarette",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/grand-pere",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/grand-pere",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/panique-cesser-fumer",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/panique-cesser-fumer",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensee-obstacle/trucs-cesser-fumer",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/jarrete/hesite-encore/pensees-obstacles/trucs-cesser-fumer",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/actualites/campagnes-mediatiques/semaine-pour-un-quebec-sans-tabac-2017",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/je-minforme/tabas-toutes-formes/types-fumees-tabac",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/cqts/:pathMatch(.*)",
    component: component_45stubq5xUrmReWC
  },
  {
    name: component_45stubq5xUrmReWCMeta?.name,
    path: "/node/:pathMatch(.*)",
    component: component_45stubq5xUrmReWC
  }
]